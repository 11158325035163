@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/roboto/roboto-light-webfont.ttf") format("truetype"), url("../assets/fonts/roboto/roboto-light-webfont.eot");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/roboto/roboto-regular-webfont.ttf") format("truetype"), url("../assets/fonts/roboto/roboto-regular-webfont.eot");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/roboto/roboto-medium-webfont.ttf") format("truetype"), url("../assets/fonts/roboto/roboto-medium-webfont.eot");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/roboto/roboto-bold-webfont.ttf") format("truetype"), url("../assets/fonts/roboto/roboto-bold-webfont.eot");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/roboto/roboto-black-webfont.ttf") format("truetype"), url("../assets/fonts/roboto/roboto-black-webfont.eot");
  font-weight: 900;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/material-icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2") format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.one-line-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
