#root,
body,
html {
  width: 100%;
  height: 100%;
  position: relative;
  scroll-behavior: smooth;
}

div,
a {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
}
