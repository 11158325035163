:root{
    --accent: #005EDE
}

body{
    background-color: #103F62 !important;
}

/*Hover*/
.hover {
    transition: all 0.2s;
}
.hover:hover{
    color: var(--accent)
}

.black-hover {
    transition: all 0.2s;
}
.black-hover:hover {
    color: #000 !important;
}

.hover-underline{
    transition: all 0.2s;
    border-bottom: 1px solid transparent;
}

.hover-underline:hover{
    transition: all 0.2s;
    border-bottom: 1px solid gray;
}

.custom-scroll-y{
    overflow-y: auto;
}

.custom-scroll-y::-webkit-scrollbar {
    width: 20px;
    height: 30px;
}

.custom-scroll-y::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: #fff;
}
