.flex-adaptive {
    flex-direction: row-reverse;
}

@media (max-width: 1700px) {
    .flex-adaptive {
        flex-direction: column;
        align-items: end !important;
    }
}
@media (max-width: 1430px) {
    .flex-adaptive > div{
        transform: rotate(90deg) translateY(-160%) translateX(40%);
    }
}


.search-adaptive{
    width: 720px
}

@media (max-width: 1100px) {
    .search-adaptive {
        width: 100%;
    }
}

.grid-adaptive{
    grid-template-columns: 250px 1fr 250px !important;
}

@media (max-width: 1550px) {
    .grid-adaptive {
        grid-template-columns: 150px 1fr 150px !important;
    }
}

.scroll-down {
    animation: transition 0.3s;
}

.scroll-up {
    position: sticky;
    z-index: 1000;
    top: 0;
    animation: transitionReverse 0.3s;
}

@keyframes transition {
    0% {opacity: 1}
    100% {opacity: 0}
}

@keyframes transitionReverse {
    0% {opacity: 0}
    100% {opacity: 1}
}


